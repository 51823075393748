<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-row>
      <el-form :inline="true" class="demo-form-inline">
         <el-form-item label="姓名">
         <el-input v-model="name" placeholder="请输入员工姓名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <el-table :data="DataList" :fit="true" :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{ 'text-align': 'center' }" v-loading="is_loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="身份" prop="type"></el-table-column>
        <el-table-column label="账户余额" prop="bonus"></el-table-column>
         <el-table-column label="总分成金额" prop="bonus_total"></el-table-column>
        <el-table-column label="操作" >
          <template slot-scope="{ row }">
            <div class="flex flex-wrap">
              <el-button type="primary" @click="toDetails(row)" size="small">查看详情</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging-fy @currentPageChange="changeCurrentPage" :currentPage="currentPage" :total="Total"></paging-fy>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: { firstNav: "员工分成管理", secondNav: "员工分成列表" },
      DataList: [],
      is_loading: true,
      currentPage: 1,
      Total: 0,
      status: 2,
      name:''
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    toDetails(row){
      this.$router.push({
        name:'bonusDetails',
        query:{
          id:row.id
        }
      })
    },
    //列表
    getList() {
      this.is_loading = true;
      let url = "/authorization/managerUser/queryManagerListPage";
      let params = {
        pageSize: 10,
        currentPage: this.currentPage,
        status: this.status,
        name:this.name || null,
        is_employees:1
      };
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.DataList = res.data.data.rows;
          this.Total = res.data.data.total;
          this.is_loading = false;
        });
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getList();
    },
  },
};
</script>

<style lang="less">
</style>
